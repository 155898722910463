// noinspection JSUnusedGlobalSymbols
import { MemberEventSetting } from '@/controller/payloads/EventsPayloads'
import {
  ADDRESS,
  AGENDA,
  BAIL_MODULE,
  CAMPUS_EVENT,
  COMPANY,
  EXTRACTOR,
  JOBS,
  NEWS,
  PARKING,
  PARKING_BOOKING,
  PARKING_SPOT,
  ROOMS,
  SUPPLIERS,
  USER
} from './FieldsEudonet'
import { EVENT_CALENDER_MEMBER_ONLY_TAG } from './RequestConstants'

export const forceAPICacheRefresh = (route: string) => {
  if (route.includes('?')) {
    return `${route}&_vercel_no_cache=1`
  }

  return `${route}?_vercel_no_cache=1`
}

const getQueryFiltersTextParams = (queryFilters: string, queryText: string) => {
  return `${queryFilters.length > 0 ? `&queryFilters=${encodeURIComponent(queryFilters)}` : ''}${
    queryText.length > 0 ? `&queryText=${encodeURIComponent(queryText)}` : ''
  }`
}

const getPageSizeIdParams = (pageSize: number, paginationId: number) => {
  return `?pageSize=${encodeURIComponent(pageSize)}&paginationId=${encodeURIComponent(
    paginationId
  )}`
}

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// External pages
// pages that lead outside the EIP ecosystem to website not owned my EIP
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
export const ROUTE_COTERIES = 'https://www.coteries.com'
export const ROUTE_CAMIPRO = 'https://epfl-innovationpark.ch/camipro'
export const ROUTE_SCALE_UP_VAUD = 'https://www.scale-up-vaud.ch/'
export const ROUTE_EPFL_STARTUPS =
  'https://www.epfl.ch/innovation/startup/epfl-startups/list-of-startups/'
export const ROUTE_MAILCHIMP_URL =
  'https://epfl-innovationpark.us13.list-manage.com/subscribe/post?u=8eadb0aa32b17270cb346f4a7&id=3c0a271344'
export const ROUTE_TICKETING = (token: string) =>
  `https://my.eudonet.com/speCIF/EUDO_07191/PortailLocataire/Identification.aspx?token=${encodeURIComponent(
    token
  )}`
export const ROUTE_NEXTBUS =
  'https://museho.dev.openit.ch/?roid1=1970329131942542&name1=Ecublens%20VD,%20EPFL'

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// Internal pages
// pages that lead inside the EIP ecosystem, internal pages to the project
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
export const ROUTE_ROOT = '/'
export const ROUTE_NOT_FOUND = '/404'
export const ROUTE_LOGIN = '/login'
export const ROUTE_ECOSYSTEM_NEWS = '/news'
export const ROUTE_ECOSYSTEM_APPROVED_SUPPLIERS = '/best-deals'
export const ROUTE_ECOSYSTEM_NEWS_BY_TYPE = (newsType: string) =>
  `/news?news=${encodeURIComponent(newsType)}`
export const ROUTE_ECOSYSTEM_EVENTS = '/events'
export const ROUTE_ECOSYSTEM_EVENTS_BY_TYPE = (eventType: string) =>
  `/events?events=${encodeURIComponent(eventType)}`
export const ROUTE_COMPANY_LIST = '/companies'
export const ROUTE_JOB = '/job'
export const ROUTE_JOB_FILTERED = (companyName: string) => `/jobs?filter=${companyName}`
export const ROUTE_SMART_GUIDES_ADMIN = '/smart-guides/administrator/228'
export const ROUTE_SMART_GUIDES_EMPLOYEE = '/smart-guides/employee/421'
export const ROUTE_SMART_GUIDES_SUPERVISOR = '/smart-guides/supervisor/934'
export const ROUTE_TEMPLATES = '/templates'
export const ROUTE_EPFL_NEWS = 'https://news.epfl.ch/'
export const ROUTE_PORTAL = '/portal'
export const ROUTE_PORTAL_COLLABORATORS = '/portal/company-collaborators'
export const ROUTE_PORTAL_COLLABORATORS_CREATE = '/portal/company-new-collaborator'
export const ROUTE_PORTAL_JOBS = '/portal/company-jobs'
export const ROUTE_PORTAL_COMPANY_EDIT = '/portal/company-edit'
export const ROUTE_PORTAL_NEWS_CREATE = '/portal/admin/manage-news'
export const ROUTE_PORTAL_NEWS_EDIT = (id: string) => `/portal/admin/manage-news?id=${id}`
export const ROUTE_PORTAL_EVENT_CREATE = '/portal/admin/manage-events'
export const ROUTE_PORTAL_LOGO_EDIT = '/portal/admin/manage-logo'
export const ROUTE_PORTAL_EVENT_EDIT = (id: string) => `/portal/admin/manage-events?id=${id}`
export const ROUTE_PARKSHARE = '/parkshare'
export const ROUTE_FAIRMOVE_CAR_POOLING = 'https://epfl.fairpark.ch/login'
export const ROUTE_FAIRMOVE_PARKING = 'https://epfl-innovationpark.fairmove.ch/login'
export const ROUTE_PARKSHARE_MY_BOOKINGS = '/parkshare/bookings'
export const ROUTE_PARKSHARE_BOOKING_DETAILS = '/parkshare/bookings/details'
export const ROUTE_PARKSHARE_BOOKING_MANAGER = '/parkshare/manager'
export const ROUTE_SUCCESS_STORIES = '/success-stories'
export const ROUTE_TENANT_PORTAL = '/working-here/tenant-portal/'
export const ROUTE_SUCCESS_STORY_DETAILS = (id: number) => `/success-stories/details?id=${id}`
export const ROUTE_EVENTS_CALENDAR = (membersOnly?: boolean) => {
  return membersOnly
    ? `/events-calendar?${EVENT_CALENDER_MEMBER_ONLY_TAG}=${membersOnly}`
    : '/events-calendar'
}

export const ROUTE_EVENT_DETAIL = (id: number) => `/events/details?id=${id}`
export const ROUTE_NEWS_DETAIL = (id: number) => `/news/details?id=${id}`
export const ROUTE_COMPANY_DETAILS = (companyId: number) => `/companies/details?id=${companyId}`

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// External pages
// pages that lead inside the EIP ecosystem, pages that aren't in the project yet
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
export const ROUTE_BOOK_A_ROOM = '/book-a-room'
export const ROUTE_BOOK_A_ROOM_MANAGER = 'https://old.epfl-innovationpark.ch/rooms/admin'

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// API endpoints
// endpoints for the API built in the project
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

// Auth
export const FRONT_LOGIN = '/api/auth/login'
export const FRONT_TOKEN_LOGIN = '/api/auth/token-login'
export const FRONT_FORGOT_PASSWORD = '/api/auth/forgot-password'
export const FRONT_RESET_PASSWORD = '/api/auth/reset-password'
export const FRONT_PERMISSIONS_CHECKER = '/api/auth/permissions/'

// Misc
export const FRONT_REDEPLOY_WEBSITE = '/api/redeploy'

// companies
export const FRONT_COMPANY = '/api/companies/company'
export const FRONT_COMPANIES = '/api/companies/query'
export const FRONT_COMPANY_DETAILS = (companyId: number) =>
  `/api/companies/${encodeURIComponent(companyId)}`
export const FRONT_COMPANY_LOGO = (companyId: number) =>
  `/api/companies/logo/${encodeURIComponent(companyId)}`
export const FRONT_COMPANY_EMPLOYEES = '/api/companies/employees'
export const FRONT_COMPANIES_PAGINATED = (
  fetchAll: boolean,
  queryFilters: string,
  queryText: string,
  isFEIP: boolean,
  location: string,
  onlyPublished: boolean = true
) => {
  const params = new URLSearchParams()
  if (fetchAll) params.append('fetchAll', 'true')
  if (queryFilters) params.append('queryFilters', queryFilters)
  if (queryText) params.append('queryText', queryText)
  if (location) params.append('location', location)
  if (isFEIP) params.append('isEPFL', 'true')
  if (onlyPublished) params.append('publishedOnly', 'true')

  return `${FRONT_COMPANIES}?${params.toString()}`
}
// Users
export const FRONT_USERS = '/api/users/query'
export const FRONT_USER = (userId: string) => `/api/users/${encodeURIComponent(userId)}`
export const FRONT_CURRENT_USER = '/api/users/user'

// Parking
export const FRONT_PARKING_LOT_LIST = '/api/parking/parking-lots'
export const FRONT_USER_BOOKINGS = '/api/parking/bookings'
export const FRONT_FREE_SPOTS = (date: string) =>
  `/api/parking/freeSpots/${encodeURIComponent(date)}`
export const FRONT_BOOK = '/api/parking/spots/book'
export const FRONT_SPOTS_COMPANY = '/api/parking/spots/company'
export const FRONT_SPOTS = (id: string) => `/api/parking/spots/${encodeURIComponent(id)}`

// News
export const FRONT_NEWS_DETAILS = (id: string) => `/api/news/${encodeURIComponent(id)}`
export const FRONT_NEWS = `/api/news/query`
export const FRONT_NEWS_FROM_TYPE = (typeId: number) =>
  `/api/news/type/${encodeURIComponent(typeId)}`
export const FRONT_NEWS_SUCCESS_STORIES = (pageSize: number, paginationId: number) =>
  `${FRONT_NEWS}${getPageSizeIdParams(pageSize, paginationId)}&successStories=true`
export const FRONT_NEWS_PAGINATED = (
  isSuccessStories: boolean,
  queryFilters: string,
  queryText: string,
  pageSize: number,
  fetchAll?: boolean
) => {
  let params = new URLSearchParams({ pageSize: pageSize + '' })
  if (fetchAll) params.append('fetchAll', 'true')
  if (queryText) params.append('queryText', queryText)
  if (queryFilters) params.append('queryFilters', queryFilters)
  if (isSuccessStories) params.append('successStories', 'true')

  return `${FRONT_NEWS}?${params.toString()}`
}
export const FRONT_NEWS_FROM_COMPANY = (linkedCompanyId: number) =>
  `${FRONT_NEWS}?linkedCompanyId=${encodeURIComponent(linkedCompanyId)}`
export const FRONT_NEWS_ITEMS = `/api/news/items`

// Events
export const FRONT_EVENT_DETAILS = (id: string) => `/api/events/${encodeURIComponent(id)}`
export const FRONT_EVENT_DETAILS_ICAL = (id: string) => `/api/events-ical/${encodeURIComponent(id)}`
export const FRONT_EVENT_DETAILS_AGENDA = (id: number) => `/api/events/agenda/${id}`
export const FRONT_EVENTS = `/api/events/query`
export const FRONT_NEXT_EVENTS = `/api/events/next`

export const FRONT_EVENTS_FROM_TYPE = (
  typeId: number,
  numResults: number,
  upcoming: boolean = false,
  memberEventsSetting: MemberEventSetting
) =>
  `/api/events/type/${encodeURIComponent(
    typeId
  )}?numResults=${numResults}&upcoming=${upcoming}&memberEventSetting=${encodeURIComponent(
    memberEventsSetting
  )}`
export const FRONT_EVENTS_FROM_RANGE = (
  startDate: string,
  endDate: string,
  queryFilters: string,
  memberEventsSetting: MemberEventSetting = 'all'
) =>
  `/api/events/range?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
    endDate
  )}&queryFilters=${encodeURIComponent(queryFilters)}&memberEventSetting=${encodeURIComponent(
    memberEventsSetting
  )}`
export const FRONT_EVENTS_FROM_RANGE_ICAL = `/api/events/range-ical`
export const FRONT_EVENTS_PAGINATED = (
  fetchAll: boolean,
  queryFilters: string,
  queryText: string,
  upcoming: boolean,
  memberEventsSetting: MemberEventSetting = 'all'
) => {
  let params = new URLSearchParams()
  if (fetchAll) params.append('fetchAll', 'true')
  if (queryFilters) params.append('queryFilters', queryFilters)
  if (queryText) params.append('queryText', queryText)
  if (upcoming) params.append('upcoming', 'true')
  if (memberEventsSetting) params.append('memberEventSetting', memberEventsSetting)
  return `${FRONT_EVENTS}?${params.toString()}`
}

// Jobs
export const FRONT_JOBS_COMPANY = '/api/jobs/company'
export const FRONT_JOBS_POST = '/api/jobs/query'
export const FRONT_JOBS = (queryFilters: string, queryText: string) =>
  `/api/jobs/query?${getQueryFiltersTextParams(queryFilters, queryText)}`
export const FRONT_JOB_DETAILS = (jobId: number) => `/api/jobs/${encodeURIComponent(jobId)}`

// Misc
export const FRONT_WEBFLOW_SEARCH = (query: string) => `api/webflow-search?query=${query}`
export const FRONT_SPOTLIGHT = '/api/spotlight'
export const FRONT_GET_SUPPLIERS = (
  pageSize: number,
  paginationId: number,
  queryFilters: string,
  queryText: string
) =>
  `/api/approved-suppliers/query${getPageSizeIdParams(
    pageSize,
    paginationId
  )}${getQueryFiltersTextParams(queryFilters, queryText)}`
export const FRONT_CATALOG = (catalogId: number | number) =>
  `/api/catalogs/${encodeURIComponent(catalogId)}`

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// Eudonet endpoints
// endpoints used by the API to reach Eudonet endpoints
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
export const EUDONET_BASE_URL = 'https://my.eudonet.com/eudoapi'
export const EUDONET_AUTHENTICATE_TOKEN = 'https://my.eudonet.com/eudoapi/Authenticate/Token'
export const EUDONET_SEARCH_USER = `/Search/${USER}`
export const EUDONET_SEARCH_COMPANY = `/Search/${COMPANY}`
export const EUDONET_SEARCH_ADDRESS = `/Search/${ADDRESS}`
export const EUDONET_SEARCH_EVENTS = `/Search/${CAMPUS_EVENT}`
export const EUDONET_GET_EVENT = (eventId: number) => `/Search/${CAMPUS_EVENT}/${eventId}`
export const EUDONET_GET_EVENT_AGENDA = `/Search/${AGENDA}`
export const EUDONET_SEARCH_NEWS = `/Search/${NEWS}`
export const EUDONET_GET_NEWS = (newsId: number) => `/Search/${NEWS}/${newsId}`
export const EUDONET_SEARCH_PARKING_LOT_LIST = `/Search/${PARKING}`
export const EUDONET_GET_PARKING_LOT = (lotId: number) => `/Search/${PARKING}/${lotId}`
export const EUDONET_SEARCH_PARKING_BOOKING_LIST = `/Search/${PARKING_BOOKING}`
export const EUDONET_SEARCH_PARKING_BOOKING = (bookingId: number) =>
  `/Search/${PARKING_BOOKING}/${bookingId}`
export const EUDONET_DELETE_PARKING_BOOKING = (bookingId: number) =>
  `/CUD/Delete/${PARKING_BOOKING}/${bookingId}`
export const EUDONET_CREATE_PARKING_BOOKING = `/CUD/${PARKING_BOOKING}`
export const EUDONET_POST_PARKING_BOOKING_DETAILS = (bookingId: number) =>
  `/CUD/${PARKING_BOOKING}/${bookingId}`
export const EUDONET_SEARCH_PARKING_SPOT = `/Search/${PARKING_SPOT}`
export const EUDONET_SEARCH_BAIL = `/Search/${BAIL_MODULE}`
export const EUDONET_SEARCH_JOBS = `/Search/${JOBS}`
export const EUDONET_GET_CATALOG = (descId: number) => `/Catalog/${descId}`
export const EUDONET_GET_USERS = `/Search/${USER}`
export const EUDONET_GET_USER_DETAILS = (userId: string | number) => `/Search/${USER}/${userId}`
export const EUDONET_POST_USER_DETAILS = (userId: string | number) => `/CUD/${USER}/${userId}`
export const EUDONET_POST_USER_AVATAR = (userId: string | number) =>
  `/CUD/Image/${USER}/${userId}/avatar`
export const EUDONET_POST_PRODUCT_PICTURE_AVATAR = (companyId: number) =>
  `/CUD/Image/${COMPANY}/${companyId}/productPicture`
export const EUDONET_POST_COMPANY_LOGO = (companyId: number) =>
  `/CUD/Image/${COMPANY}/${companyId}/avatar`
export const EUDONET_POST_NEWS_PICTURE = (newsId: number) =>
  `/CUD/Image/${NEWS}/${newsId}/newsImage`
export const EUDONET_POST_EVENT_PICTURE = (eventId: number) =>
  `/CUD/Image/${CAMPUS_EVENT}/${eventId}/eventImage`
export const EUDONET_GET_COMPANY_DETAILS = (companyId: number) => `/Search/${COMPANY}/${companyId}`
export const EUDONET_POST_ADDRESS_DETAILS = (addressId: number) => `/CUD/${ADDRESS}/${addressId}`
export const EUDONET_POST_JOBS = `/CUD/${JOBS}`
export const EUDONET_POST_JOB_DETAILS = (jobId: number) => `/CUD/${JOBS}/${jobId}`
export const EUDONET_DELETE_JOB = (jobId: number) => `/CUD/Delete/${JOBS}/${jobId}`
export const EUDONET_POST_COMPANY_DETAILS = (companyId: number) => `/CUD/${COMPANY}/${companyId}`
export const EUDONET_POST_NEWS_DETAILS = (newsId: number) => `/CUD/${NEWS}/${newsId}`
export const EUDONET_POST_EVENTS_DETAILS = (eventId: number) => `/CUD/${CAMPUS_EVENT}/${eventId}`
export const EUDONET_POST_USER = `/CUD/${USER}`
export const EUDONET_POST_NEWS = `/CUD/${NEWS}`
export const EUDONET_POST_EVENTS = `/CUD/${CAMPUS_EVENT}`
export const EUDONET_POST_ADDRESS = `/CUD/${ADDRESS}`
export const EUDONET_POST_AGENDA = `/CUD/${AGENDA}`
export const EUDONET_SEARCH_SUPPLIERS = `/Search/${SUPPLIERS}`
export const EUDONET_DELETE_AGENDA = (agendaId: number) => `/CUD/Delete/${AGENDA}/${agendaId}`
export const EUDONET_SEARCH_SCRAPER = `/Search/${EXTRACTOR}`
export const EUDONET_SEARCH_SCRAPER_BY_ID = (scraperId: number) =>
  `/Search/${EXTRACTOR}/${scraperId}`
export const EUDONET_SEARCH_ROOMS = `/Search/${ROOMS}`
